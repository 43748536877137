<template>
     <div class="flex flex-col bgSecondary5 relative md:mr-20 md:ml-20 mr-5 ml-5 mb-28">
            <div class="flex flex-col md:mt-28 mt-20">
                <p id="title" class="tohoma font-bold primary mb-8">Job Openings</p>
                <p class="helvatica font-normal md:text-base text-sm secondary">We are looking for team members for the following
                    positions.</p>
            </div>
            <div class="flex md:flex-row flex-col md:mt-28 mt-20 md:pr-20 md:pl-20  relative justify-center">
                <div class="flex flex-col  md:pr-10 md:w-1/2">
                    <div class="flex flex-col mb-7">
                        <RouterLink to="/careers/licensed">
                            <p id="titlelink" class="tohoma mb-4 font-bold primary md:text-base text-sm">Licensed Aircraft Technician </p>
                        </RouterLink>
                        <p class="font-normal mb-4 primary md:text-sm text-xs">Bombardier BD-700 Type rated , B1 and/or B2</p>
                        <p class="font-normal mb-4 secondary md:text-base text-sm">Istanbul ,Turkey</p>
                        <hr class="secondary3">
                    </div>
                    <div class="flex flex-col mb-7">
                        <RouterLink to="/careers/licensed">
                            <p id="titlelink" class="tohoma mb-4 font-bold primary md:text-base text-sm">Licensed Aircraft Technician </p>
                        </RouterLink>
                        <p class="font-normal mb-4 primary md:text-sm text-xs">Hawker Beechcraft BAe 125 Series Type rated, B1 and/or B2</p>
                        <p class="font-normal mb-4 secondary md:text-base text-sm">Istanbul ,Turkey</p>
                        <hr class="secondary3">
                    </div>
                    <div class="flex flex-col mb-7">
                        <RouterLink to="/careers/projectengineer">
                            <p id="titlelink" class="tohoma mb-4 font-bold primary md:text-base text-sm">Project Engineer</p>
                        </RouterLink>
                        <p class="font-normal mb-4 primary md:text-sm text-xs">BSc degree in aeronautical, mechanical, electric/electronics or industrial Engineering with min 3 years aviation sector experience</p>
                        <p class="font-normal mb-4 secondary md:text-base text-sm">Istanbul ,Turkey</p>
                        <hr class="secondary3">
                    </div>
                </div>
                <div class="flex flex-col md:pl-10 md:w-1/2">
                    <div class="flex flex-col mb-7">
                        <RouterLink to="/careers/licensed">
                            <p id="titlelink" class="tohoma mb-4 font-bold primary md:text-base text-sm">Licensed Aircraft Technician </p>
                        </RouterLink>
                        <p class="font-normal mb-4 primary md:text-sm text-xs">Bombardier CL-600-2B16/2B19 Type Rated, B1 and/or B2</p>
                        <p class="font-normal mb-4 secondary md:text-base text-sm">Istanbul ,Turkey</p>
                        <hr class="secondary3">
                    </div>
                    <div class="flex flex-col mb-7">
                        <RouterLink to="/careers/licensed">
                            <p id="titlelink" class="tohoma mb-4 font-bold primary md:text-base text-sm">Licensed Aircraft Technician </p>
                        </RouterLink>
                        <p class="font-normal mb-4 primary md:text-sm text-xs">Gulfstream GIV/GIV-SP/GIV-X/GV Basic Model/GV Series Type rated, B1 and/or B2 </p>
                        <p class="font-normal mb-4 secondary md:text-base text-sm">Istanbul ,Turkey</p>
                        <hr class="secondary3">
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
   
}
</script>
<style scoped>
    #titlelink:hover{
    color: #CC0605;
    transition: 1s;
}

#title{
    font-size: 32px;
}
@media(max-width: 480px) {
    #title{
        font-size: 16px;
    }
}
</style>