<template>
    <div id="popupSuccess" class="flex w-full h-full">
        <div class="flex absolute flex-col top-1/2 md:left-1/3 left-10 rounded-lg">
            <div id="popupIcon" class="flex absolute bottom-52">
                <img class="w-16 md:w-10" src="../assets/succesImage.png">
            </div>
            <p id="title" class="tohoma font-bold text-2xl text-center mb-8 mt-20">Congratulations!</p>
            <p class="helvatica font-normal text-base text-center p-8">Your mail has been sent successfully.</p>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
#popupSuccess {
    background-color: rgba(0, 0, 0, .6);
}

#title {
    color: #1EAD07;
}

#popupSuccess div {
    background-color: white;
}
#popupIcon{
    left: 40%;
    border-radius: 50%;
}
</style>