<template>
    <div class="flex md:flex-row flex-col red-bg w-full md:pr-20 md:pl-20 pr-5 pl-5 pt-8 pb-8 md:mr-4">
        <div class="flex flex-col md:w-1/2 w-full">
            <p id="title" class="tohoma font-bold secondary5  mb-8">{{ title }}</p>
            <p class="mb-8 helvatica font-normal secondary5 md:text-base text-xs">{{ content }}</p>
        </div>
        <img class="md:w-1/2 w-full md:ml-4 rounded-lg" :src="require(`../assets/${image}`)"> 
    </div>
</template>
<script>

export default {
    props:{
        title:String,
        content:String,
        image:String
    }
}
</script>
<style scoped>
#title{
    font-size: 32px;
}
@media(max-width:480px) {
    #title{
        font-size: 16px;
    }
}
</style>