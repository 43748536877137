<template>
    <div class="w-full bgSecondary5 flex flex-col relative">
        <div class="h-20"></div>
        <div class="w-full relative">
            <img class="w-full" src="../assets/careerFrame.png">
            <div class="flex flex-row absolute justify-center items-center md:top-10 md:left-20 top-5 left-5">
                <RouterLink to="/careers"><img class=" w-2 md:mr-5 mr-2" src="../assets/backIcon.png"></RouterLink>
                <RouterLink class="tohoma font-normal secondary5 md:text-base text-xs" to="/careers#careers">Back
                </RouterLink>
            </div>
            <div id="title" class="flex flex-col absolute md:left-20 left-10 md:bottom-1/3 bottom-6">
                <p class="tohoma font-bold secondary5 md:mb-8 mb-2">Project Engineer</p>
                <p class="helvatica font-bold secondary5 md:text-xl text-sm">We are looking for a talent based in Istanbul
                    ,Turkey</p>
            </div>
        </div>
        <div class="w-full flex flex-col mt-28 relative md:ml-20 pl-10 md:pl-0 mb-8">
            <p class="tohoma font-bold md:text-2xl text-xl mb-5">Responsibilities</p>
            <div v-for="(item, index) in Responsibilities" :key="index" class="flex flex-row mb-4">
                <div id="dot" class="w-3 red-bg h-3 mt-1.5"></div>
                <p class="pl-5 w-2/3">{{ item }}</p>
            </div>
        </div>
        <div class="w-full flex flex-col relative md:ml-20 pl-10 md:pl-0 mb-8">
            <p class="tohoma font-bold md:text-2xl text-xl mb-5">Required Skills and Qualifications</p>
            <div v-for="(item, index) in requiredSkills" :key="index" class="flex flex-row mb-4">
                <div id="dot" class="w-3 red-bg h-3 mt-1.5"></div>
                <p class="pl-5 w-2/3">{{ item }}</p>
            </div>
        </div>
        <button @click="openPopup()"
            class="red-bg md:ml-20 ml-10 rounded-lg secondary5 tohoma font-bold text-sm h-11 mb-64">Apply</button>
        <div v-if="popupStatu" id="popup" class="flex absolute w-screen h-screen justify-center md:mt-20">
            <div class="flex flex-col items-center relative md:w-1/2 w-4/5 justify-center rounded-lg mt-32 p-5">
                <div class="w-full h-20 rounded-lg"></div>
                <GeneralapplicationformComponents class=" w-full md:m-0 mb-20 mt-20"
                    advert="Project Engineer Application" @success-popup="onSuccessPopup" />
                <div class="h-20 w-full rounded-lg"></div>
                <button @click="popupStatu = !popupStatu" class=" secondary2 absolute md:top-10 top-2 md:pl-0 pl-20 right-0">X</button>
            </div>
        </div>
        <SuccesspopupComponents v-if="succesPopup" class="absolute w-full h-screen top-0 z-20" />
    </div>
</template>
<script>
import SuccesspopupComponents from './SuccesspopupComponents.vue';
import GeneralapplicationformComponents from './GeneralapplicationformComponents.vue';
export default {
    components: {
        GeneralapplicationformComponents,
        SuccesspopupComponents
    },
    data() {
        return {
            succesPopup:false,
            popupStatu: false,
            Responsibilities: [
                "Responsible for working within all stages of Aircraft Maintenance project management (i.e. Maintenance department, Logistics & Purchasing department and Quality & Safety Directorate)",
                "Manage projects from beginning to end (i.e. quotation / work package preparation, WINGS usage, customer software usage if required, documentation check, reporting to manufacturer and invoice/claim preparation as applicable)",
                "Monitor the progress of a project to ensure that it is working within the given TAT",
                "Provide efficient communication between the operator, manufacturer and internal departments as necessary",
                "Generate progress reports for on going maintenance and inform operator/customer and related departments",
                "Periodically check and update MPD with the new AMM, EMM revisions as applicable",
                "Follow Part-145 requirements"
            ],
            requiredSkills: [
                "Bachelor’s degree preferably in Engineering",
                "Having at least 2 years of experience in Engineering & Planning",
                "Fluent in English",
                "Energetic, proactive and able to build effective collaboration with all levels of the organization, very good at planning & organization, so that s/he can manage multitasking environment at its best",
                "Fast computer typing skills (MS Office)",
                "CAMP & WINGS is a plus",
                "Strong communications and organizational skills",
                "Advanced level in Excel",
                "Flexibility to travel"
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        onSuccessPopup() {
                window.scrollTo(0, 0);
                this.popupStatu = false
                this.succesPopup = true;
                setTimeout(() => {
                    this.succesPopup=false;
                }, 3000);
        },
        openPopup(){
            window.scrollTo(0,0)
            this.popupStatu=!this.popupStatu
        }
    },
}
</script>
<style scoped>
#title :nth-child(1) {
    font-size: 40px;
}

#dot {
    border-radius: 50%;
}

button {
    width: 120px;
}

#popup {
    background-color: rgba(0, 0, 0, .6);
    height: 150rem;
}

#popup div {
    background-color: #FAFAFA;
    height: 40rem;
}

.secondary5 {
    color: #FAFAFA !important;
}

@media(max-width:480px) {
    #title :nth-child(1) {
        font-size: 16px;
    }
}
</style>