<template>
  <div class="flex flex-col items-center justify-center md:mt-20 mt-1 mb-20 md:ml-20 ml-5 ">
    <div class="md:flex hidden flex-col w-full">
      <div class="flex overflow-x-scroll overflow-hidden">
        <img id="imageId" v-for="(item, index) in images" :src="item" :key="index" class="w-full mr-5">
      </div>
      <div class="mt-8 flex justify-center items-center">
        <button :class="{ 'scroll': left }" @click="scrollLeft"></button>
        <button :class="{ 'scroll': right }" @click="scrollRight"></button>
      </div>
    </div>
    <div id="mobile" class="flex flex-col w-full md:pr-20 pr-5">
      <div class="flex w-full">
        <img id="imageId" :src="singleImages" class="w-full mr-5">
      </div>
      <div class="mt-8 flex justify-center items-center">
        <button 
        @click="changeImage(item,index)" v-for="(item,index) in images" :key="index"
        :class="{ 'active': selectedButton === index }"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedButton:null,
      images: [
        require(`../assets/HomeServices3.jpg`),
        require(`../assets/HomeServices1.jpg`),
        require(`../assets/AboutHistory.jpg`),
      ],
      singleImages:require(`../assets/HomeServices3.jpg`),
      left: true,
      right: false,
      indexImage:1
    }
  },
  mounted() {
    var statu = 0;
    setInterval(() => {
      if (statu == 0) {
        this.scrollRight()
        statu = 1;
      } else {
        this.scrollLeft()
        statu = 0;
      }
    }, 7000);


    this.changeImage(this.images[0],0)
    setInterval(() => {
      this.callButtons();
    },3000)
  },
  methods: {
    callButtons(){
      setTimeout(() => {
        if(this.indexImage==0){
          this.changeImage(this.images[this.indexImage],this.indexImage)
          this.indexImage=1;
        }else if(this.indexImage==1){
          this.changeImage(this.images[this.indexImage],this.indexImage)
          this.indexImage=2;
        }else{
          this.changeImage(this.images[this.indexImage],this.indexImage)
          this.indexImage=0;
        }
      }, 3000);
    },
    scrollLeft() {
      this.left = true
      this.right = false
      const scrollContainer = document.querySelector('.overflow-x-scroll');
      scrollContainer.scrollLeft -= 500;
    },
    scrollRight() {
      this.left = false
      this.right = true
      const scrollContainer = document.querySelector('.overflow-x-scroll');
      scrollContainer.scrollLeft += 500;
    },
    changeImage(item,index){
      this.selectedButton=index
      this.singleImages=item
    }
  }
};
</script>

<style scoped>
#mobile{
  display: none;
}
.active{
  background-color: #CC0605;
  width: 32px;
  height: 10px;
  border-radius: 10px;
}
.flex {
  display: flex;
}

.overflow-x-scroll {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#imageId {
  max-width: 80rem;
  max-height: 25rem;
  border-radius: 8px;
}

/* Hide scrollbar on webkit-based browsers */
.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

button {
  background-color: #E4E4E4;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

.scroll {
  width: 32px;
  background-color: #CC0605;
  border-radius: 10px;
}

@media (max-width: 480px) {
    #mobile{
      display: flex;
    }
}
</style>
