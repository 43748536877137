<template>
    <div class="flex flex-col w-full bgSecondary5">
        <div id="careers" class="h-20"></div>
        <div class="w-full relative">
            <img id="contactImg" class="w-full" src="../assets/careeer.png">
            <div class="absolute flex flex-row md:top-10 md:left-20 top-5 left-5 justify-center items-center">
                <router-link id="homelink" to="/#home"
                    class="secondary5 tohoma md:text-base text-xs font-normal md:mr-5 mr-2">Home</router-link>
                <img class="w-2 h-3 md:mr-5 mr-2" src="../assets/vectorcareer.png">
                <p class="secondary5 tohoma font-bold md:text-base text-xs">Career</p>
            </div>
            <div class="absolute flex flex-col md:bottom-24 md:left-20 bottom-9 left-5">
                <p id="title" class="secondary5 font-bold md:mb-8 mb-2">Careers</p>
                <p class="secondary5 helvatica font-normal md:text-xl text-xs">‘‘Join us and boost your career in aviation.’’
                </p>
            </div>
        </div>
        <JobopeningsaComponent id="job" />
        <div class="flex flex-col md:pl-20 md:pr-20 pr-5 pl-5 mb-28" id="general">
            <p id="generalApp" class="tohoma primary font-bold">General Application Form</p>
            <p class="helvatica font-normal md:text-base text-sm secondary mt-8 mb-8">Please use following form for your job
                applications for a position other than above indicated ones.</p>
            <GeneralapplicationComponents class="md:pl-20" advert="General Application" @success-popup="onSuccessPopup" />
        </div>
        <SuccesspopupComponents v-if="succesPopup" class="absolute w-full h-full top-0 z-20" />
    </div>
</template>
<script>
import SuccesspopupComponents from "@/components/SuccesspopupComponents.vue"
import GeneralapplicationComponents from "@/components/GeneralapplicationformComponents.vue"
import JobopeningsaComponent from "@/components/JobopeningsComponent.vue"
export default {
    components: {
        SuccesspopupComponents,
        JobopeningsaComponent,
        GeneralapplicationComponents
    },
    data() {
        return {
            succesPopup: false,
        }
    },
    mounted() {
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
    methods: {
        onSuccessPopup() {
            window.scrollTo(0, 0);
            this.succesPopup = true;
            setTimeout(() => {
                this.succesPopup = false;
            }, 3000);
        },
    },
    watch: {
        // URL'deki hash değerini izle
        '$route.hash': function (newHash, oldHash) {
            // Eğer hash değeri değiştiyse
            if (newHash !== oldHash) {
                // Yeni hash değerine göre ilgili öğeyi al
                const element = document.querySelector(newHash);
                if (element) {
                    // Öğeyi görünür hale getir
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }

}
</script>
<style scoped>
#homelink:hover {
    transition: all 1s;
    font-weight: 700
}

#contenttitle {
    font-size: 40px;
}

#content {
    margin-top: 14%;
}

hr {
    border: 1px solid;
}

#generalApp {
    font-size: 32px;
}

input {
    border: 1px solid #4F4F4F;
    border-radius: 10px;
}

#homelink:hover {
    font-weight: 700;
    transition: 1s all;
}

#title {
    font-size: 40px;
}

#contactImg {
    filter: brightness(80%) grayscale(30%);
}

@media (max-width: 480px) {
    #title {
        font-size: 16px;
    }

    #generalApp {
        font-size: 16px;
    }
}
</style>