<template>
    <div class=" flex flex-col items-center justify-center bgSecondary5" id="home">
        <div id="home" class="h-20"></div>
        <ScrollingComponent class="w-full" />
        <!-- Services -->
        <div class="primary2 w-full flex flex-col relative md:pr-16 md:pl-16 pr-5 pl-5 pt-8 pb-8 md:mt-28 mt-5">
            <div class="flex flex-col">
                <h2 id="text40" class="secondary5 font-bold tohoma mb-8">Services</h2>
                <p class="md:text-base text-sm secondary5 font-normal helvatica">We provide business jet MRO services
                    including AOG support as well as flight and ground operation support services.</p>
            </div>
            <div class="mt-16 flex md:flex-row flex-col  w-full relative">
                <div class="flex flex-col md:w-1/3 w-full md:pr-5 pt-5 md:pb-5">
                    <img class="rounded-lg h-4/6" src="../assets/HomeServices1.jpg">
                    <h1 class="tohoma font-bold secondary5 md:text-2xl text-base mt-6 h-9">Maintenance Services</h1>
                    <p class="secondary5 font-normal md:text-base text-sm mt-6 h-28">Currently we perform line maintenance including unscheduled and scheduled tasks for Bombardier BD-700 , CL-600-2B16 & 2B19 and Hawker 750/800XP/850XP/900XP series and plan to expand our capability list to cover other types.</p>
                    <span class="flex pt-6 helvatica secondary5 md:text-sm text-xs font-bold">Learn more <router-link
                            to="/maintenanceservices#maintenance" class="flex justify-center items-center ml-3.5 cursor-pointer"><img
                                class="flex " src="../assets/homeLearnMore.png"></router-link></span>
                </div>
                <div class="flex flex-col md:w-1/3 w-full md:p-5 pt-5">
                    <img class="rounded-lg h-4/6" src="../assets/HomeServices2.jpg ">
                    <h1 class="tohoma font-bold secondary5 md:text-2xl text-base mt-6 h-9">Operation Services</h1>
                    <p class="secondary5 font-normal md:text-base text-sm mt-6 md:h-28 h-10">As an AOC prelicence holder
                        from Turkish DGCA, we plan to start flight and
                        ground operations for business jets in 2nd half of 2023.</p>
                    <span class="flex mt-10 md:mt-0 pt-6 helvatica secondary5 md:text-sm text-xs font-bold">Learn more
                        <router-link to="/operationservices#operation"
                            class="flex justify-center items-center ml-3.5 cursor-pointer"><img class="flex "
                                src="../assets/homeLearnMore.png"></router-link></span>
                </div>
                <div class="flex flex-col md:w-1/3 w-full md:p-5 pt-5 ">
                    <img class="rounded-lg h-4/6" src="../assets/HomeServices3.jpg">
                    <h1 class="tohoma font-bold secondary5 md:text-2xl text-base mt-6 h-9">AOG Support</h1>
                    <p class="secondary5 font-normal md:text-base text-sm mt-6 md:h-28 h-10">Our AOG desk and mobile teams
                        are always ready to solve your urgent needs for parts and defect rectifications.</p>
                    <span class="flex pt-6 mt-10 md:mt-0 helvatica secondary5 md:text-sm text-xs font-bold">Learn more
                        <router-link to="/aogsupport#aogsupport" class="flex justify-center items-center ml-3.5 cursor-pointer"><img
                                class="flex " src="../assets/homeLearnMore.png"></router-link></span>
                </div>
            </div>
        </div>
        <div id="about"
            class="flex  bgSecondary5 justify-center  md:flex-row flex-col md:mt-20 mt-10 mb-20 md:mb-28 md:pr-16 md:pl-16 pr-5 pl-5 relative w-full">
            <div class="flex flex-col xl:w-1/2 w-full mb-10 xl:mb-0 xl:pr-20 relative">
                <h1 id="text40" class="font-bold  mb-8">About Us</h1>
                <p class="md:text-base text-sm text-left font-normal mb-8">Falcon Aerospace is an integrated aviation
                    company with maintenance/repair/overhaul , air taxi operations and ground services capability under one
                    umbrella.</p>
                <button id="moreAbout" @click="this.$router.push({ path: 'about#about' })"
                    class="flex text-center justify-center primary2 w-40 h-11 items-center text-sm font-bold  rounded-lg">
                    More about us
                </button>
            </div>
            <div id="image-background" class=" md:w-1/2 relative">
                <img class="z-10  relative rounded-lg" src="../assets/_MG_1729.jpg">
                <div id="redBackground"
                    class="rounded-lg primary2 absolute  w-full h-full flex justify-center top-10 right-10 z-0 "></div>
            </div>
        </div>
        <!-- Why falcon Aerospace -->
        <div class="bgSecondary5 md:pr-16 md:pl-16 pr-5 pl-5  pb-8 flex flex-col w-full">
            <p id="text40" class="font-bold primary tohoma pt-8 flex items-center justify-center w-full">Why Falcon
                Aerospace?
            </p>
            <div class="md:flex md:flex-row grid mt-20">
                <div class="flex flex-col md:p-6 md:w-1/4 pb-6 w-full card-hover md:h-72 rounded-lg">
                    <img src="../assets/quality.png" class="w-10 float-left pb-7">
                    <p class="tohoma font-bold md:text-2xl text-base primary">Quality & Safety</p>
                    <p class="helvatica secondary text-base font-normal secondary">Complying with national & international
                        aviation regulations and quality/safety system standards are first priority for us.</p>
                </div>
                <div class="flex flex-col md:p-6 md:w-1/4 w-full pb-6 card-hover md:h-72 rounded-lg">
                    <img src="../assets/Vector.png" class="w-10 float-left pb-7">
                    <p class="tohoma font-bold md:text-2xl text-base primary">Success Guarantee</p>
                    <p class="helvatica secondary text-base font-normal secondary">We always perform detailed analysis and
                        planning with the highest work performance to deliver on budget services.</p>
                </div>
                <div class="flex flex-col md:p-6 md:w-1/4 w-full pb-6 card-hover md:h-72 rounded-lg">
                    <img src="../assets/bx-time-five.png" class="w-10 float-left pb-7">
                    <p class="tohoma font-bold md:text-2xl text-base primary">On Time Service</p>
                    <p class="helvatica secondary text-base font-normal secondary">All required resources are always well
                        planned and
                        made ready to redeliver customer aircraft on time.</p>
                </div>
                <div class="flex flex-col md:p-6 md:w-1/4 w-full pb-6 card-hover md:h-72 rounded-lg">
                    <img src="../assets/user.png" class="w-10 float-left pb-7">
                    <p class="tohoma font-bold md:text-2xl text-base primary">Professional Team</p>
                    <p class="helvatica secondary text-base font-normal secondary">Our well trained and experienced
                        technicians and engineers with solution oriented and pro-active mindset are key factor for our
                        quality services.</p>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <OtomoticscrollingComponent :imagesList="images" lengthArray="5" />
        <!-- Contact US -->
        <ContactusComponent 
        @success-popup="onSuccessPopup"
        />
        <!-- Latest News -->
        <!-- <CarouselComponent title="Latest News" /> -->
        <SuccesspopupComponents v-if="succesPopup" class="absolute w-full h-full top-0 z-20" />
    </div>
</template>
<script>
import SuccesspopupComponents from '@/components/SuccesspopupComponents.vue'
import ScrollingComponent from '../components/ScrollingComponent.vue'
import ContactusComponent from '../components/ContactusComponent.vue'
import OtomoticscrollingComponent from "../components/OtomoticscrollComponent.vue"
//import CarouselComponent from '../components/CarouselComponent.vue'
export default {
    name: "homePage",
    components: {
        SuccesspopupComponents,
        ScrollingComponent,
        ContactusComponent,
        OtomoticscrollingComponent,
      //  CarouselComponent
    },
    data() {
        return {
            succesPopup:false,
            images: [
                {
                    name: "logo-6.png",
                    pdf: "SHGM.pdf"
                },
                {
                    name: "logo-5.png",
                    pdf: "SAN MARINO.pdf"
                },
                {
                    name: "logo-3.png",
                    pdf: ""
                },
                {
                    name: "logo-2.png",
                    pdf: ""
                },
                {
                    name: "logo-8.png",
                    pdf: ""
                },
                {
                    name: "logo-9.png",
                    pdf: ""
                },
                {
                    name: "logo-10.png",
                    pdf: "ebaa.pdf"
                },
            ],
        }
    },
    mounted() {
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
    methods:{
        onSuccessPopup() {
            this.succesPopup = true;
            window.scrollTo(0, 0);
            setTimeout(() => {
                this.succesPopup = false;
            }, 3000);
        },
    },
    watch: {
        // URL'deki hash değerini izle
        '$route.hash': function (newHash, oldHash) {
            // Eğer hash değeri değiştiyse
            if (newHash !== oldHash) {
                // Yeni hash değerine göre ilgili öğeyi al
                const element = document.querySelector(newHash);
                if (element) {
                    // Öğeyi görünür hale getir
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }
}
</script>
<style scoped>
#moreAbout {
    color: #FAFAFA !important;
}

#text40 {
    font-size: 40px;
}

@media (min-width:500px) and (max-width: 960px) {
    #redBackground {
        right: 10px;
        top: 10px;
        height: 50%;
    }

    #image-background {
        margin-left: 4rem;
    }
}

@media (max-width: 480px) {
    #redBackground {
        left: 10px;
        top: 10px;
    }

    #text40 {
        font-size: 20px;
    }
}
</style>