import { createRouter, createWebHistory } from "vue-router"
import HomePage from "@/pages/HomePage"
import AboutPage from "@/pages/AboutPage"
import MaintanenceservicesPages from "@/pages/MaintenanceservicesPage"
import OperationservicesPage from "@/pages/OperationservicesPage"
import AogsupportPage from "@/pages/AogsupportPage"
import CareersPage from "@/pages/CareersPage"
import ProjectengineerComponents from "@/components/ProjectengineerComponents"
import LicansedaircraftComponent from "@/components/LicancedaircraftComponents"
import NewsComponent from "@/components/NewsComponents"
import ContactusPage from "@/pages/ContactusPage"
const routes = [
    {
        name: "home",
        path: "/",
        component: HomePage
    },
    {
        name: "about",
        path: "/about",
        component: AboutPage
    },
    {
        name: "maintanenceservices",
        path: "/maintenanceservices",
        component: MaintanenceservicesPages
    },
    {
        name: "operationservices",
        path: "/operationservices",
        component: OperationservicesPage
    },
    {
        name: "aogsupport",
        path: "/aogsupport",
        component: AogsupportPage
    },
    {
        name: 'careers',
        path: '/careers',
        component: CareersPage,
    },
    {
        name: 'projectengineer',
        path: '/careers/projectengineer',
        component: ProjectengineerComponents
    },
    {
        name:'Licensed',
        path:'/careers/licensed',
        component:LicansedaircraftComponent
    },
    {
        name:"news",
        path:"/news/:id",
        component:NewsComponent
    },
    {
        name:"contactus",
        path:"/contactus",
        component:ContactusPage
    }

]
const router = createRouter({
    routes,
    history: createWebHistory()
})

export default router;