<template>
    <div class="flex flex-col w-full items-center relative md:mt-10 mt-5 md:mb-28 mb-20 md:pl-20 md:pr-20 pl-5 pr-5">
        <div class="flex flex-row items-center absolute md:left-20 left-5">
            <RouterLink id="homelink" class="tohoma secondary md:text-base text-xs font-normal " :to="to">{{ getRouter }}</RouterLink>
            <img class="w-2 ml-5 mr-5" src="../assets/VectorStartPage.png">
            <p class="primary tohoma font-bold md:text-base text-xs">{{ thisPage }}</p>
        </div>
        <div class="flex flex-col mt-14 justify-center items-center w-full">
            <p id="title" class="primary tohoma font-bold mb-8 ">{{ title }}</p>
            <p class="helvatica font-normal secondary md:text-xl text-base text-center">{{ content }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props:{
      to:String,
      getRouter:String,
      thisPage:String,
      title:String,
      content:String
    },
    data() {
      return {
        
      }
    },
}
</script>
<style scoped>
  #homelink:hover{
    color: #CC0605;
  }
#title{
  font-size: 40px;
}
@media (max-width: 480px) {
  #title{
    font-size: 20px;
  }
}
</style>