<template>
    <div class="flex flex-col w-full bgSecondary5 relative">
        <div id="news" class="h-20"></div>
        <div class="w-full relative">
            <img id="newsImg" class="w-full" src="../assets/news.png">
            <div class="flex flex-row absolute justify-center items-center md:top-10 md:left-20 top-5 left-5">
                <RouterLink id="link" class="secondary5 tohoma font-normal md:text-base text-xs md:mr-5 mr-2" to="/#home">
                    Home
                </RouterLink>
                <RouterLink to="/#home"><img class=" w-2 md:mr-5 mr-2" src="../assets/vectorcareer.png"></RouterLink>
                <p class="tohoma font-normal secondary5 md:text-base text-xs">News</p>
            </div>
            <div id="title" class="flex flex-col absolute md:left-20 left-5 md:bottom-1/3 bottom-10">
                <p class="tohoma font-bold secondary5">Falcon Aerospace News</p>
            </div>
        </div>
        <div class="flex flex-col md:ml-20 md:mr-20 mr-5 ml-5 md:mt-28 mt-20">
            <p id="newsTitle" class="primary font-bold tohoma mb-2 text-center">{{ news["title1"] }}</p>
            <p id="newsTitle" class="primary font-bold tohoma mb-6 text-center">{{ news["title2"] }}</p>
            <p class="helvatica font-normal md:text-base text-sm primary mb-4">{{ news["description1"] }}</p>
            <p class="helvatica font-normal md:text-base text-sm primary mb-4">{{ news["description2"] }}</p>
            <p class="helvatica font-normal md:text-base text-sm primary mb-4">{{ news["description3"] }}</p>
            <p class="helvatica font-normal md:text-base text-sm primary mb-4">{{ news["description4"] }}</p>
            <p class="helvatica font-normal md:text-base text-sm primary mb-4">{{ news["description5"] }}</p>
            <div class="flex md:flex-row flex-col md:mt-8 mt-5">
                <img src="../assets/HomeNews1.jpg" class="md:h-96 md:w-1/2 w-full rounded-lg md:mr-2 md:pb-0 mb-2">
                <img :src="require(`../assets/${dynamicImg}`)" class="md:h-96 md:w-1/2 w-full rounded-lg md:ml-2">
            </div>
        </div>
        <CarouselComponent title="Related News" />
    </div>
</template>
<script>
import CarouselComponent from './CarouselComponent.vue';
export default {
    components: {
        CarouselComponent
    },
    data() {
        return {
            newsIndex: null,
            news: [],
            dynamicImg: "HomeNews2.jpg",
            newsData: [
                {
                    title1:"Business Aviation is Continuing to Grow.",
                    title2:"FALCON AEROSPACE AR-GE A.Ş. is Granted as SHY-145 Authorised Maintenance Organisation by Turkish DGCA.",
                    description1:"Falcon Aerospace Arge A.Ş. (FALCON AEROSPACE) which was established in 2014 in order to present AOC management and technical/maintenance support services to local and foreign business jet owner and operators has been granted as SHY-145 Approved Maintenance Organisation on January 12th of 2023 after a successful audit carried out at its state of the art hangar at Istanbul Ataturk Airport in last December by Turkish DGCA.",
                    description2:"FALCON AEROSPACE has just started maintenance activities on Bombardier Global (BD-700) series in line maintenance level, meanwhile investing for base maintenance.",
                    description3:"By foreseeing increase in operational density in business aviation in not only locally but also neighbour countries just after pandemic and by knowing healthy foundation in business aviation certainly requires robust and sustainable technical infrastructure , FALCON AEROSPACE having fully equipped 1500 sqm hangar at ISL has finalized required preparation with its well qualified managers, engineers and technicians, successfully.",
                    description4:"FALCON AEROSPACE is also targeting to expand current capabilities to cover other business jets types as well as base maintenance.",
                    description5:"By having Turkish DGCA SHY-6A prelicence on business jet AOC holder and SHY-22 ground operation prelicence, FALCON AEROSPACE is also hard working to get permanent flight and ground operation licences as planned.",
                    img: "AboutHistory.jpg"
                },
                {
                    title1: "FALCON AEROSPACE Continues to Expand its Capability Step by Step.",
                    title2:"",
                    description1:"Falcon Aerospace Arge A.Ş. (FALCON AEROSPACE) which was established in 2014 in order to present AOC management and technical/maintenance support services to local and foreign business jet owner and operators has been granted as San Marino CAR-145 Approved Maintenance Organisation on February 24th of 2023 after a successful audit carried out between 23-24 February.",
                    description2:"By presenting maintenance services to foreign business jet operators, FALCON AEROPSPACE targets to maintain T7 registered business jets flying to/from Turkey and neighbour countries and contribute to Turkish civil aviation sector and overall economy.",
                    description3:"In that context, FALCON AEROSPACE aims to expand its maintenance capabilities in Q2 of 2023 to cover FAR Part-145 certification to maintain N- registered business jets as well as other types.",
                    description4:"",
                    description5:"",
                    img: "HomeNews2.jpg"
                },
                {
                    title1: "FALCON AEROSPACE is Participating EBACE 2023.",
                    title2:"",
                    description1:"FALCON AEROSPACE is participating EBACE 2023 (The 2023 European Business Aviation Convention & Exhibition) Europe’s premier on-demand aircraft and advanced air mobility event which will be held at Palexpo in Geneva, Switzerland, and the Geneva International Airport from Tuesday 23 May through Thursday 25 May 2023.",
                    description2:"As FALCON AEROSPACE, we will be happy to host you at our booth No: X41 to present our company and capabilities and to discuss further business opportunities.",
                    description3:"",
                    description4:"",
                    description5:"",
                    img: "news3Img.png"
                }
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        console.log("route params: ", this.$route.params)
        this.newsIndex = this.$route.params.id
        console.log("selma: ", typeof this.newsIndex)
        this.news = this.newsData[this.newsIndex]
        this.dynamicImg = this.news["img"]
    },

    watch: {
        '$route': function (to) {
            if (to.params.id && !isNaN(parseInt(to.params.id))) {
                this.newsIndex = to.params.id;
                this.news = this.newsData[this.newsIndex];
                this.dynamicImg = this.news["img"];
                console.log("index: ", this.news);
                window.scrollTo(0, 0);
            }
        }
    },

}
</script>
<style scoped>
#link:hover {
    font-weight: 700;
}

#title p {
    font-size: 40px;
}

#relatednews {
    font-size: 40px;
}

#newsImg {
    filter: brightness(80%) grayscale(30%);
    /* filtre değerleri */
}

#newsTitle {
    font-size: 32px;
}

@media(max-width:480px) {
    #title p {
        font-size: 20px;
    }

    #newsTitle {
        font-size: 16px;
    }
}
</style>