<template>
    <div class="flex md:flex-row flex-col w-full primary2 md:pl-20 md:pr-20 pr-5 pl-5">
        <div class="flex flex-col pt-8 md:w-1/2 w-full md:pr-8 mb-8">
            <p id="title" class="font-bold secondary5 pb-8 ">History</p>
            <p class="helvatica font-normal md:text-base text-sm secondary5">Falcon Aerospace Arge A.Ş. was founded on July 10th
of 2014 by our Chairman Mr. Murat Burak Atabey in
order to present flight and ground operation services
to corporate and business jet sector in our hangar
with all infrastructure needed at Istanbul Ataturk
Airport.</p>
        </div>
        <div class="md:w-1/2 w-full pt-8 pb-8">
            <img class=" rounded-lg" src="../assets/AboutHistory.jpg">
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
#title{
    font-size: 32px;
}
@media(max-width: 480px) {
    #title{
        font-size: 16px;
    }
}
</style>