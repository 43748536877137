<template>
    <div class="bgSecondary5 md:mt-28 mt-20 mb-28 flex flex-col  md:pr-16 md:pl-16 pr-5 pl-5">
        <p id="relatednews"  class="tohoma font-bold flex justify-center items-center md:mb-12 mb-8">{{title}}</p>
        <div id="isDesktop" class="flex flex-row justify-center ">
            <button @click="shiftMethod()"
                class="secondary5 mr-4  h-9 w-9 card-shadow flex justify-center items-center"><img
                    class="flex justify-center items-center" src="../assets/chevron.png"></button>
            <div v-for="(item, index) in newsList" :key="index" class=" flex flex-col  p-4 pl-10 pr-10 w-96  card-hover">
                <router-link :to="'/news/' + index">
                    <img class="rounded-lg h-44 w-80" :src="require(`../assets/${item.imgSrc}`)">
                    <p class="tohoma font-bold md:text-sm text-xs  pt-5 pb-5 primary">
                        {{ item.title }}</p>
                    <p class="tohoma font-normal text-xs secondary">{{ item.date }}
                    </p>
                    <p class="secondary helvatica md:text-base text-sm mt-6">{{ item.description }}</p>
                    <span class="flex pt-6 helvatica secondary text-sm font-bold">Read more <router-link :to="'/news/' + index"
                            class="flex justify-center items-center ml-3.5 cursor-pointer"><img class="flex "
                                src="../assets/icon-r.png"></router-link></span>
                </router-link>
            </div>
            <button @click="popMethod()" class="secondary5 ml-4  h-9 w-9 card-shadow flex justify-center items-center"><img
                    class="flex justify-center items-center" src="../assets/chevron-2.png"></button>
        </div>
        <div id="isMobile" class="flex flex-row w-full justify-center relative">
            <button @click="popMethod()" class="secondary5 mr-2  h-6 w-6 card-shadow flex justify-center items-center"><img
                    class="flex justify-center items-center" src="../assets/chevron.png"></button>
            <div v-for="(item, index) in newsList2.slice(0,1)" :key="index"
                class=" flex flex-col w-full pt-4 pb-4 pl-2 pr-2 card-hover">
                <router-link :to="`/news/${mobileRouteIndex}`">
                    <img class="rounded-lg h-44 w-full" :src="require(`../assets/${item.imgSrc}`)">
                    <p class="tohoma font-bold text-sm  pt-5 pb-5 primary">
                        {{ item.title }}</p>
                    <p class="tohoma font-normal text-xs secondary">{{ item.date }}
                    </p>
                    <p class="secondary helvatica md:text-base text-sm mt-6">{{ item.description }}</p>
                    <span class="flex pt-6 helvatica secondary md:text-sm text-xs font-bold">Read more <router-link :to="'/news/' + index"
                            class="flex justify-center items-center ml-3.5 cursor-pointer"><img class="flex "
                                src="../assets/icon-r.png"></router-link></span>
                </router-link>
            </div>
            <button @click="popMethod()" class="secondary5 ml-2  h-6  w-6 card-shadow flex justify-center items-center"><img
                    class="flex justify-center items-center" src="../assets/chevron-2.png"></button>
        </div>
    </div>
</template>
<script>
export default {
        props:{
            title:String
        },

    data() {
        return {
            mobileRouteIndex:0,
            windowWidth: 0,
            isMobile: false,
            newsList: [
                {
                    imgSrc: "HomeNews1.jpg",
                    title: "Business Aviation is Continuing to Grow. FALCON AEROSPACE AR-Ge A.Ş. is Granted as SHY-145 Authorised Maintenance Organisation by Turkish DGCA.",
                    date: "Falcon Aerospace News Bulletin No: 2023/01 dated 12.01.2023",
                    description: "Falcon Aerospace Arge A.Ş. (FALCON AEROSPACE) which was established in 2014 in order to present AOC management and technical/maintenance support services to local and foreign business jet owner and operators has been granted as SHY-145 Approved Maintenance Organisation on January 12th of 2023 after a successful audit carried out at its state of the art hangar at Istanbul Ataturk Airport in last December by Turkish DGCA.",
                },
                {
                    imgSrc: "HomeNews2.jpg",
                    title: "FALCON AEROSPACE Continues to Expand its Capability Step by Step.",
                    date: "Falcon Aerospace News Bulletin No 2023/03 dated 24.02.2023",
                    description: "Falcon Aerospace Arge A.Ş. (FALCON AEROSPACE) which was established in 2014 in order to present AOC management and technical/maintenance support services to local and foreign business jet owner and operators has been granted as San Marino CAR-145 Approved Maintenance Organisation on February 24th of 2023 after a successful audit carried out between 23-24 February.",
                },
                {
                    imgSrc: "news3Img.png",
                    title: "FALCON AEROSPACE is Participating EBACE 2023.",
                    date: "",
                    description: "FALCON AEROSPACE is participating EBACE 2023 (The 2023 European Business Aviation Convention & Exhibition) Europe’s premier on-demand aircraft and advanced air mobility event which will be held at Palexpo in Geneva, Switzerland, and the Geneva International Airport from Tuesday 23 May through Thursday 25 May 2023.",
                }
            ],
            newsList2: [
            {
                    imgSrc: "HomeNews1.jpg",
                    title: "Business Aviation is Continuing to Grow. FALCON AEROSPACE AR-Ge A.Ş. is Granted as SHY-145 Authorised Maintenance Organisation by Turkish DGCA.",
                    date: "Falcon Aerospace News Bulletin No: 2023/01 dated 12.01.2023",
                    description: "Falcon Aerospace Arge A.Ş. (FALCON AEROSPACE) which was established in 2014 in order to present AOC management and technical/maintenance support services to local and foreign business jet owner and operators has been granted as SHY-145 Approved Maintenance Organisation on January 12th of 2023 after a successful audit carried out at its state of the art hangar at Istanbul Ataturk Airport in last December by Turkish DGCA.",
                },
            ]

        }
    },
    methods: {
        popMethod() {
            const endElement = this.newsList.pop();
            this.newsList.unshift(endElement);
            this.newsList2.pop()
            this.newsList2.push(endElement);
            this.mobileRouteIndex-=1;
            if(this.mobileRouteIndex==-1){
                this.mobileRouteIndex=2;
            }
        },
        shiftMethod() {
            const firstElement = this.newsList.shift();
            this.newsList.push(firstElement);
            this.newsList2.pop()
            this.newsList2.push(firstElement)
            this.mobileRouteIndex+=1;
            if(this.mobileRouteIndex==3){
                this.mobileRouteIndex=0;
            }
        }
    }
}
</script>
<style scoped>
button {
    border-radius: 50%;
    margin-top: 25%;
}
#relatednews{
    font-size: 40px;
}
@media (max-width: 480px) {
    #relatednews{
        font-size: 20px;
    }
    #isMobile {
        display: flex;
    }

    #isDesktop {
        display: none;
    }
}

@media (min-width: 481px) {
    #isMobile {
        display: none;
    }

    #isDesktop {
        display: flex;
    }
}</style>