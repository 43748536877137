<template>
  <div class="bgSecondary5 relative w-full flex justify-center items-center">
    <div id="isDesktop" class="m-20 flex flex-row items-center justify-center" ref="imagesContainer">
      <div class="mr-6 ml-6" v-for="(image, index) in copiedImages.slice(0, lengthArray)" :key="index">
        <img class=" cursor-pointer" @click="openPdf(image.pdf)" :src="require(`../assets/${image.name}`)" />
      </div>
    </div>
    <div id="isMobile" class="m-10 flex flex-row items-center justify-center" ref="imagesContainer">
      <div class="mr-6 ml-6" v-for="(image, index) in copiedImages.slice(0, 1)" :key="index">
        <img @click="openPdf(image.pdf)" class="w-48" :src="require(`../assets/${image.name}`)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imagesList: Array,
    lengthArray: Number
  },
  data() {
    return {
      copiedImages: []
    };
  },
  mounted() {
    this.copiedImages = this.imagesList;
    setInterval(this.changeImage, 3000);
    console.log("images: ", this.copiedImages);
  },
  methods: {
    changeImage() {
      const firstElemnt = this.copiedImages.shift()
      this.copiedImages.push(firstElemnt);
    },
    openPdf(item) {
      if (item) {
        const pdfPath = require(`../assets/${item}`).default;
        window.open(pdfPath, '_blank');
      }
    },

  },
  watch: {

  },
};
</script>

<style scoped>
img {
  height: 100px;
  max-width: 280px;
}

@media (max-width: 480px) {
  #isMobile {
    display: flex;
  }

  #isDesktop {
    display: none;
  }
}

@media (min-width: 481px) {
  #isMobile {
    display: none;
  }

  #isDesktop {
    display: flex;
  }
}
</style>
