<template>
  <div>
    <HeaderComponent class="w-full fixed z-30" />
    <router-view />
    <div>
      <FooterComponent/>
    </div>
  </div>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
 import HeaderComponent from './components/HeaderComponent.vue';
export default {
  name: 'App',
  components: {
    FooterComponent,
    HeaderComponent,
  },
  data() {
    return {
    }
  },
  mounted() {
    document.title = 'Falcon Aerospace'; // Sayfanın yeni sekme başlığı
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Tahoma", sans-serif;
  font-size: 14px;
}

footer {
  width: 100%;
  background-color: #e4e4e4;
  font-family: "Tahoma", sans-serif;
  font-size: 14px;
  position: relative;
  bottom: 0;
  left: 0;
  min-height: 460px;
}

.logo-about {
  min-width: 30%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.logo-about .logo {
  float: left;
  flex: 1;
}

.logo-about .logo img {
  width: 170px;
}

.logo-about .logo p {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #4F4F4F;
}



</style>
