<template>
    <div class="w-full bgSecondary5 flex flex-col relative">
        <div class="h-20"></div>
        <div class="w-full relative">
            <img class="w-full" src="../assets/careerFrame.png">
            <div class="flex flex-row absolute justify-center items-center md:top-10 md:left-20 top-5 left-5">
                <RouterLink to="/careers"><img class=" w-2 mr-5" src="../assets/backIcon.png"></RouterLink>
                <RouterLink class="tohoma font-normal secondary5 md:text-base text-xs" to="/careers#careers">Back
                </RouterLink>
            </div>
            <div id="title" class="flex flex-col absolute md:left-20 left-10 md:bottom-1/3 bottom-6">
                <p class="tohoma font-bold secondary5 md:mb-8 mb-2">Licensed Aircraft Technician</p>
                <p class="helvatica font-bold secondary5 md:text-xl text-sm">We are looking for a talent based in Istanbul
                    ,Turkey</p>
            </div>
        </div>
        <div class="w-full flex flex-col md:mt-28 mt-20 relative md:ml-20 mb-8 pl-10 md:pl-0">
            <p class="tohoma font-bold md:text-2xl text-lg mb-5">Responsibilities</p>
            <div v-for="(item, index) in Responsibilities" :key="index" class="flex flex-row mb-4">
                <div id="dot" class="w-3 red-bg h-3 mt-1.5"></div>
                <p class="pl-5 w-2/3">{{ item }}</p>
            </div>
        </div>
        <div class="w-full flex flex-col relative md:ml-20 mb-8 pl-10 md:pl-0">
            <p class="tohoma font-bold md:text-2xl text-lg mb-5">Required Skills and Qualifications</p>
            <div v-for="(item, index) in requiredSkills" :key="index" class="flex flex-row mb-4">
                <div id="dot" class="w-3 red-bg h-3 mt-1.5"></div>
                <p class="pl-5 w-2/3">{{ item }}</p>
            </div>
        </div>
        <button @click="openPopup()"
            class="red-bg md:ml-20 ml-10 rounded-lg secondary5 tohoma font-bold text-sm h-11 mb-64">Apply</button>
        <div v-if="popupStatu" id="popup" class="flex absolute w-screen h-screen justify-center md:mt-20">
            <div class="flex flex-col items-center relative md:w-1/2 w-4/5 justify-center rounded-lg mt-32 p-5">
                <div class="w-full h-20 rounded-lg"></div>
                <GeneralapplicationformComponents class=" w-full md:m-0 mb-20 mt-20" advert="Licensed Aircraft Technician Application"
                    @success-popup="onSuccessPopup" />
                <div class="h-20 w-full rounded-lg"></div>
                <button @click="popupStatu = !popupStatu"
                    class=" secondary2 absolute md:top-10 top-2 md:pl-0 pl-20 right-0">X</button>
            </div>
        </div>
        <SuccesspopupComponents v-if="succesPopup" class="absolute w-full h-screen top-0 z-20" />
    </div>
</template>
<script>
import GeneralapplicationformComponents from './GeneralapplicationformComponents.vue';
import SuccesspopupComponents from './SuccesspopupComponents.vue';
export default {
    components: {
        GeneralapplicationformComponents,
        SuccesspopupComponents
    },
    data() {
        return {
            succesPopup: false,
            popupStatu: false,
            Responsibilities: [
                "Performing the relevant tasks or inspections in according with SHY-145 regulations , eusing approved and updated maintenance data and documents.",
                "Performing and signing off the A/C line maintenance scheduled and unscheduled tasks.",
                "Leading and monitoring his/her team that he/she may be assigned effectively.",
                "Must be detail oriented, deadline sensitive and able to work effectively in a team setting.",
                "Troubleshooting, removal, testing, repair, modification, installation and maintenance of aircraft systems and components .",
                "Operating all required tools and equipment necessary to accomplish job assignment.",
                "Completing all required maintenance technical records and reporting in an accurate way and timely manner.",
                "Interpreting and working in accordance with written work instructions, technical manuals, engineering drawings, engineering orders, job cards, blueprints, schematics.",
                "Ensure airworthiness and safety aircraft .",
                "Issue a CRS when maintenance performed per SHY-145 regulations."
            ],
            requiredSkills: [
                "A, B1 or B2 rated licensed under SHY-66 or EASA Part 66 required",
                "Personel whose license endorsed at least one of the following aircraft types; Bombardier BD-700 series , Bombardier CL-600-2B16/ CL-600-2B19 Series, Hawker Beechcraft BAe 125 Series 750/800XP/850XP/900XP ve Gulfstream GIV/GIV-SP/GIV-X/GV/GV-SP",
                "The current maintenance experience shall cover at least 6 months within the last 2 years",
                "Shall meet to SHT-66 language requirements",
                "Graduated from Civil Aviation Faculties or Aviation Technical High Schools",
                "Residing in İstanbul",
                "Ability and flexibility to work and to adapt in different environments",
                "Self-motivated, team player",
                "Having a valid type B driver’s license",
                "Having no travel restrictions"
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        onSuccessPopup() {
            window.scrollTo(0, 0);
            this.popupStatu = false
            this.succesPopup = true;
            setTimeout(() => {
                this.succesPopup = false;
            }, 3000);
        },
        openPopup(){
            window.scrollTo(0,0)
            this.popupStatu=!this.popupStatu
        }
    },
}
</script>
<style scoped>
#title :nth-child(1) {
    font-size: 40px;
}

#dot {
    border-radius: 50%;
}

button {
    width: 120px;

}

.secondary5 {
    color: #FAFAFA !important;
}

#popup {
    background-color: rgba(0, 0, 0, .6);
    height: 158rem;
}

#popup div {
    background-color: #FAFAFA;
    height: 40rem;
}

@media(max-width:480px) {
    #title :nth-child(1) {
        font-size: 16px;
    }

    #popup {
        height: 195rem;
    }
}
</style>