<template>
    <div class="flex flex-col  bgSecondary5">
        <div id="aogsupport" class="h-20"></div>
        <PagestartComponent to="/#home" getRouter="Home" thisPage="AOG Support" title="AOG Support" content="Our AOG desk and mobile teams are always ready to solve your urgent 
                needs for parts and defect rectifications." />
        <div id="maintenance" class="flex md:flex-row flex-col red-bg w-full md:pr-20 md:pl-20 pr-5 pl-5 pt-8 pb-8 md:mr-4">
            <div class="flex flex-col md:w-1/2 w-full">
                <p id="title" class="tohoma font-bold secondary5  mb-8">Maintenance</p>
                <p class="mb-8 helvatica font-normal secondary5 md:text-base text-xs">For your urgent maintenance needs our mobile teams are ready 7/24 basis.</p>
                <p id="title" class=" md:mt-10 mt-5 helvatica secondary5 font-bold">aog@falconaerospace.ist</p>
                <p id="title" class=" mt-2 mb-4 helvatica secondary5 font-bold">+90 532 570 7000</p>
            </div>
            <img class="md:w-1/2 w-full md:ml-4 rounded-lg" :src="require(`../assets/Engine2.png`)">
        </div>
        <div id="parts" class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 md:mt-28 mt-20">
            <p id="title" class="primary font-bold tohoma mb-8">Parts</p>
            <p class="helvatica font-normal secondary md:text-base text-xs mb-8">For your urgent parts requests our logistics team is at your service as 7/24 basis.</p>
            <p id="title" class="helvatica secondary font-bold md:mb-8 mb-4">aog@falconaerospace.ist</p>
            <p id="title" class="helvatica secondary font-bold mb-8">+90 546 542 5061</p>
            <TwoimageComponents :images="partsImages" />
        </div>
    </div>
</template>
<script>
import PagestartComponent from '@/components/PagestartComponent.vue';
import TwoimageComponents from '@/components/TwoimageComponents.vue';
export default {
    components: {
        PagestartComponent,
        TwoimageComponents
    },
    data() {
        return {
            partsImages: [
                "parts1.jpg",
                "parts2.jpg"
            ]
        }
    },
    mounted(){
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth'});
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
    watch: {
    // URL'deki hash değerini izle
    '$route.hash': function (newHash, oldHash) {
      // Eğer hash değeri değiştiyse
      if (newHash !== oldHash) {
        // Yeni hash değerine göre ilgili öğeyi al
        const element = document.querySelector(newHash);
        if (element) {
          // Öğeyi görünür hale getir
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }
}
</script>
<style scoped>
#title {
    font-size: 32px;
}
@media(max-width: 480px) {
    #title{
        font-size: 16px;
    }
}
</style>