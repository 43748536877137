<template>
    <div class="flex flex-col bgSecondary5">
        <div id="operation" class="h-20"></div>
        <PagestartComponent to="/#home" getRouter="Home" thisPage="Operation Services" title="Operation Services" content="As Falcon Aerospace , we present an integrated solution covering not only maintenance/technical 
            support but also flight and ground operation services to our esteemed business jet owners and clients." />
        <ImageandTextComponent id="aircraft" title="Aircraft Management"
            content="As Falcon Aerospace, we have pre-licence from Turkish DGCA to have Air Taxi Operator's Certificate and plan to get it in 2nd half of 2023."
            image="Aircraft.jpg" />
        <div id="jet" class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 md:mt-28 mt-20">
            <p id="title" class="primary font-bold tohoma mb-8">Jet Charter Services</p>
            <p class="helvatica font-normal secondary md:text-base text-xs mb-8">As Falcon Aerospace, we have pre-licence
                from Turkish DGCA to have Air Taxi Operator's Certificate and plan to get it in 2nd half of 2023</p>
            <TwoimageComponents :images="jetCharterImages" />
        </div>
        <ImageandTextComponent id="ground" title="Ground Operation Services" content="As Falcon Aerospace, we have SHY-22 ground service organisation 
            pre-licence from Turkish DGCA and start our ground operation services by 2023 Q3."
            image="Groundoperationimage.jpg" />
        <div id="camo" class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 mt-28">
            <p id="title" class="primary font-bold tohoma mb-8">CAMO Services</p>
            <p class="helvatica font-normal secondary md:text-base text-xs mb-8">Falcon Aerospace is capable to perform
                Part-M maintenance management
                activities including airworthiness reviews for business jet operators and owners.</p>
            <TwoimageComponents :images="camoservicesImages" />
        </div>
    </div>
</template>
<script>
import PagestartComponent from '@/components/PagestartComponent.vue';
import ImageandTextComponent from '@/components/ImageandTextComponent.vue';
import TwoimageComponents from '@/components/TwoimageComponents.vue';
export default {
    components: {
        PagestartComponent,
        ImageandTextComponent,
        TwoimageComponents
    },
    data() {
        return {
            jetCharterImages: [
                "jetCharter2.jpg",
                "maintenance1.jpg"
            ],
            camoservicesImages: [
                "camoservices1.jpg",
                "HomeServices1.jpg"
            ]
        }
    },
    mounted() {
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
    watch: {
    // URL'deki hash değerini izle
    '$route.hash': function (newHash, oldHash) {
      // Eğer hash değeri değiştiyse
      if (newHash !== oldHash) {
        // Yeni hash değerine göre ilgili öğeyi al
        const element = document.querySelector(newHash);
        if (element) {
          // Öğeyi görünür hale getir
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }
}
</script>
<style scoped>
#title {
    font-size: 32px;
}

@media(max-width:480px) {
    #title {
        font-size: 16px;
    }
}
</style>