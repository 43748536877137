<template>
    <div class="flex md:flex-row flex-col md:pr-20 pr-2">
        <img class=" rounded-lg md:w-1/2 w-full m-2.5"  :src="require(`../assets/${images[0]}`)">
        <img class="md:w-1/2 w-full m-2.5 rounded-lg"  :src="require(`../assets/${images[1]}`)">
    </div>
</template>
<script>
export default {
    props:{
        images:Array
    }
}
</script>
<style scoped>
    
</style>